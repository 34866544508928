.progressbar {
  height: 20px;
  background-color: #e1e1e1;
  box-sizing: border-box;
}

.progress {
  background-color: #1976d2;
  height: 100%;
  transition: width 0.5s;
}
