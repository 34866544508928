// TabPanel
// this uses flex wherever possible
// notice overflow: hidden which is required for scrolling to work
.tabpanel {
    display: flex;
    flex-flow: column;
    overflow: hidden;

    .tabbody {
        flex: 1;
        display: flex;
        flex-flow: column;
        overflow: hidden;
        position: relative;
    }

    .animated-tabbodydiv {
      width: 100%;
      margin-top: 1rem;
      transform:  translateX(0%);
      display: none;
      opacity: 0;

      &.enter {
        display: flex;
        flex: 1;
        transform: translateX(-105%);
      }

      &.enter-done {
        display: flex;
        flex: 1;
        transform: translateX(0%);
        opacity: 1;
      }

      &.exit {
        display: flex;
        flex: 1;
        transform: translateX(105%);
        opacity: 0;
      }

      &.exit-done {
        display: none;
        transform: translateX(105%);
        opacity: 0;
      }
    }
    .tabbodydiv {
        flex: 1;
        display: flex;
        flex-flow: column;
        overflow: hidden;
    }
    // direct first child
    .tabbodydiv >:first-child{
        flex: 1;
        overflow: hidden;
    }
}
