.header {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  background-color: #1976d2;
    padding: 1rem 1rem 0;
    justify-content: space-between;
    align-items: center;

    img {
        height: 50px;
        width: auto;
    }

    .settings-icon {
            color: #494949;

        a {
            vertical-align: middle;

        }
    }
}
