.intro-navigation {
    &-container {
        width: 100%;
        z-index: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    &-next-button, &-back-button {
        padding: 1rem;
    }
    &-next-button {
        flex: 1;
        background-color: #1976d2;
        text-align: center;
        color: white;
        font-size: 1.5rem;
        font-weight:bold;
    }

    &-back-button {
        box-sizing: border-box;
        color: white;
        font-size: 1.5rem;
        background-color: #d56f06;
    }
}

.intro-container-logo {
  padding: 2rem;
    background-color: #1976d2;
}

.crito-logo-intro {
    width: 100%;
    height: auto;

    &-small {
        width: 33%;
        max-width: 100px;
    }
}


.intro-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    //background-image: url('./marketGuyBackground.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.intro-container-wrapper {
    display: flex;
    flex: 1;
    overflow: scroll;
    padding: 20px 20px !important;
        flex-direction: column;


    .intro-container-content {
           flex: 1;
           overflow: scroll;
           background-color: rgba(255,255,255,0.8);
           padding: 0 2rem;
        p {
            font-size: 1.2rem;
            line-height: 1.5rem;

            &.intro-warning {
                font-size: 0.8rem;
                line-height: 1rem;
                color: red;
            }
        }
    }
}

.toggle-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.cookietoggle-label {
    margin-left: 0.5rem;
    color: #444;
}
