$fori-grey: #494949;
.close-icon {
    text-align: right;
}
.settings-list {
    padding: 0;
    margin: 0;

    li {
        text-align: center;
        list-style: none;
        padding: 0 0 1rem 0 ;
        //border-bottom: 1px solid #c9c9c9;


        a {
            color: $fori-grey;
            font-weight: 400;
            text-transform: uppercase;
            text-decoration: none;
        }
    }
}

.header-bar {
    display: flex;
    box-sizing: border-box;
    padding: 1rem;
    align-items: start;
    justify-content: space-between;
    color: $fori-grey;
    text-align: center;
    width: 100%;
    flex: 1;

    &-text {
      flex: 1;
        font-weight: bold;
        font-size: 1.25rem;
    }
}

.app-version {
  padding: 1rem;
  color: lightgrey;
}
