body {
    overscroll-behavior-y: contain;
    background-color: #f7f7f7;
}

html, body, #root, .App {
  height: 100%;
}

.App {
  display: flex;
flex-direction: column;
justify-content: flex-start;
align-content: center;
align-items: center;
}
