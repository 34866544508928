.hunt-list-item-image {
  display: block;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-background-position: center;
  background-position: center;
  width: 100%;
  object-fit: cover;
  width: 50%;
}

.hunt-list-item {
  opacity: 0;
  transition: opacity 1000ms !important;
  text-decoration: none;

  &.enter-done {
    opacity: 1;
  }
}
