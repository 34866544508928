.legal-page {
  width: 100%;
  box-sizing: border-box;

  a {
    color: #494949;
  }
}



.legal-urls {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            font-size:1.2rem;
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid #d2d2d2;

            &:last-child {
                border-bottom: none;
            }

            a {
                color: #494949;
                display: flex;
                width: 100%;
                flex-direction: row;
                justify-content: space-between;
            }
            .legal-link-text {
                margin-right: 0.5rem;

                svg {
                    margin-left: 0.5rem;
                }
            }
        }
    }
}
