.hunt-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-sizing: border-box;
  flex: 1;
}

.hunt-list-item {
  width: 100%;
  box-sizing: border-box;
}

.hunt {
  display: flex;
  flex: 1;
  flex-direction: column;
  transition: opacity 1000ms;
  opacity: 0;
}

.hunt.enter-done {
  opacity: 1;
}

.icon-toolbar {
  text-align: right;
  margin-top: 1rem;
  & .icon {
    color: #494949;
  }
}
